import { useEffect } from 'react';
import axios from 'axios';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import BottomNavigation from './components/BottomNavigation';
import Card from './pages/Card';
import Tasks from './pages/Tasks';

function App() {
  useEffect(() => {
    // Инициализация Telegram WebApp SDK
    const tg = window.Telegram.WebApp;

    tg.ready(); // Сообщает Telegram, что приложение готово к работе

    // Получаем данные пользователя
    const user = tg.initDataUnsafe?.user;

    // Если данные пользователя есть, отправляем их на сервер
    if (user) {
      const userId = user.id;
      const nickname = user.username || `${user.first_name} ${user.last_name}`;

      // Отправка запроса на сервер для записи в базу данных
      axios.post('/api/users', {
        userId,
        nickname,
      })
      .then((response) => {
        console.log('Пользователь записан в базу данных:', response.data);
      })
      .catch((error) => {
        console.error('Ошибка при записи пользователя:', error);
      });
    }
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/cards' element={<Card />} />
        <Route path='/tasks' element={<Tasks />} />
      </Routes>

      <BottomNavigation />
    </BrowserRouter>
  );
}

export default App;
