import React from 'react'
import { BsCoin } from "react-icons/bs";

function CardsHeader() {
  return (
    <div className='HomeHeader'>
        <div className="HomeHeader__container">
            <div className="left">
                DOR RITO
            </div>

            <div className="right">
                <div className="right__block">
                    <div className="text">
                        340
                    </div>

                    <div className="icon">
                        <BsCoin />
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CardsHeader