import React, { useState } from 'react';
import { BsCoin, BsArrowRight, BsCheck2, BsLightningChargeFill } from "react-icons/bs";
import WebApp from '@twa-dev/sdk'

function TapBlock() {
  const user = WebApp.initDataUnsafe?.user

  const [balance, setBalance] = useState(0);

  const [isTapped, setIsTapped] = useState(false);

  // Функция для увеличения баланса на 1 при нажатии на картинку
  const handleTap = () => {
    setBalance(balance + 1);
    
    // Включаем эффект нажатия
    setIsTapped(true);

    setTimeout(() => {
      setIsTapped(false);
    }, 150);
  };

  return (
    <div className='TapBlock'>
      <div className="TapBlock__container">

        <div className="valueMoney">
          <div className="value">
            {balance}
          </div>

          <div className="icon">
            $FAP
          </div>
        </div>

        <div className="valueFarm">
          500 <span>$FAP</span> 1h farm
        </div>

        <div className="levelRange">
          <div className="levelValue">

          </div>
        </div>

        <div className="textRange">
          <div className="block">
            <div className="icon">
              <BsCheck2 />
            </div>
            <div className="text">
              6 см.
            </div>
          </div>

          <div className="block">
            <div className="text">
              7 см.
            </div>
            <div className="icon">
              <BsArrowRight />
            </div>
          </div>
        </div>

        <div className="tapBlock">
          <div 
            className={`image ${isTapped ? 'tapped' : ''}`} 
            onClick={handleTap}
          > 
            <img 
              src="https://i.postimg.cc/g2Vf9HY1/photo-2024-09-06-15-24-31.jpg" 
              alt="" 
            />
          </div>
        </div>

        <div className="quanityTap">
            <div className="icon">
              <BsLightningChargeFill />
            </div>

            <div className="text">
              1500/1500
            </div>
        </div>
      </div>
    </div>
  );
}

export default TapBlock;
