import React from 'react'
import { BsCoin } from "react-icons/bs";

function CardBlock(props) {
  return (
    <div className='CardBlock'>
        <div className="image">
            <img src={props.image && props.image} alt="" />
        </div>

        <div className="price">
            {props.price} <div className="icon"><BsCoin /></div>
        </div>

        <div className="button">
            <a href="#">Купить</a>
        </div>
    </div>
  )
}

export default CardBlock