import React, { useState } from 'react';
import { BsCoin, BsArrowRight, BsCheck2 } from "react-icons/bs";

function TapBlock() {
  // Состояние для хранения баланса
  const [balance, setBalance] = useState(0);

  // Состояние для анимации нажатия
  const [isTapped, setIsTapped] = useState(false);

  // Функция для увеличения баланса на 1 при нажатии на картинку
  const handleTap = () => {
    setBalance(balance + 1);
    
    // Включаем эффект нажатия
    setIsTapped(true);

    // Отключаем эффект нажатия после 150 мс (анимация)
    setTimeout(() => {
      setIsTapped(false);
    }, 150);
  };

  return (
    <div className='TapBlock'>
      <div className="TapBlock__container">
        <div className="valueMoney">
          <div className="icon">
            <BsCoin />
          </div>
          <div className="value">
            {balance} {/* Отображаем текущее значение баланса */}
          </div>
        </div>

        <div className="tapBlock">
          <div 
            className={`image ${isTapped ? 'tapped' : ''}`} 
            onClick={handleTap}
          > {/* Добавляем обработчик клика и класс для анимации */}
            <img 
              src="https://i.postimg.cc/g2Vf9HY1/photo-2024-09-06-15-24-31.jpg" 
              alt="" 
            />
          </div>
        </div>

        <div className="levelRange">
          <div className="levelValue">
            {/* Дополнительные элементы, если нужно */}
          </div>
        </div>

        <div className="textRange">
          <div className="block">
            <div className="icon">
              <BsCheck2 />
            </div>
            <div className="text">
              Пассив
            </div>
          </div>

          <div className="block">
            <div className="text">
              Полу-пассив
            </div>
            <div className="icon">
              <BsArrowRight />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TapBlock;
