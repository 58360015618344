import React from 'react';
import CardBlock from './CardBlock';

// Массив данных с карточками
const cardsData = [
  {
    image: "https://sun9-44.userapi.com/impg/-yo16QNLp9-snrXWW1mDBuTaWj-Cxv-A5E-f_A/fcsEgQalRc4.jpg?size=604x340&quality=95&sign=06ddac3da2a31020c877bc83c7dec54f&type=album",
    price: "100"
  },
  {
    image: "https://yastatic.net/naydex/yandex-search/gQ17s3X01/d23afeyrX/dy5_8AhOJbdboF78abc9470xmIfrd3t05p7m-ilwu3KgS3OQcmEt_XrBn_fnoEf69xzn2J7g-lxUUXbQOAIOj3Y3HvU5VznqKvlGoTmXMfe1RO2LrwYTZZL374t9PLNaiFN-NIO9rCjXIVICLxG617M85pDulMNgsLhj9AhbF",
    price: "200"
  },
  {
    image: "https://sun9-44.userapi.com/impg/-yo16QNLp9-snrXWW1mDBuTaWj-Cxv-A5E-f_A/fcsEgQalRc4.jpg?size=604x340&quality=95&sign=06ddac3da2a31020c877bc83c7dec54f&type=album",
    price: "400"
  },
  {
    image: "https://sun9-44.userapi.com/impg/-yo16QNLp9-snrXWW1mDBuTaWj-Cxv-A5E-f_A/fcsEgQalRc4.jpg?size=604x340&quality=95&sign=06ddac3da2a31020c877bc83c7dec54f&type=album",
    price: "700"
  },
  {
    image: "https://sun9-44.userapi.com/impg/-yo16QNLp9-snrXWW1mDBuTaWj-Cxv-A5E-f_A/fcsEgQalRc4.jpg?size=604x340&quality=95&sign=06ddac3da2a31020c877bc83c7dec54f&type=album",
    price: "1500"
  },
  {
    image: "https://sun9-44.userapi.com/impg/-yo16QNLp9-snrXWW1mDBuTaWj-Cxv-A5E-f_A/fcsEgQalRc4.jpg?size=604x340&quality=95&sign=06ddac3da2a31020c877bc83c7dec54f&type=album",
    price: "1800"
  },
  {
    image: "https://sun9-44.userapi.com/impg/-yo16QNLp9-snrXWW1mDBuTaWj-Cxv-A5E-f_A/fcsEgQalRc4.jpg?size=604x340&quality=95&sign=06ddac3da2a31020c877bc83c7dec54f&type=album",
    price: "2000"
  }
];

function CardsWrapper() {
  return (
    <div className='CardsWrapper'>
      <div className="CardsWrapper__container">
        {cardsData.map((card, index) => (
          <CardBlock key={index} image={card.image} price={card.price} />
        ))}
      </div>
    </div>
  );
}

export default CardsWrapper;
